body {
    font-family: Oxygen,Montserrat, Arial, sans-serif;
}

.dark-green-bg {
  background-color: #276845;
}

input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:  blue;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #909;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #909;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #909;
}
::-ms-input-placeholder { /* Microsoft Edge */
   color:    #909;
}

input[type="text"]::placeholder, input[type="select"]::placeholder { /* Most modern browsers support this now. */
   color:    #bebeba;
}

.login-card{
    width: 350px;
}

.header-section {
    background: #fff!important;
}

.nav-brand {
    display: inline-block;
    padding: 0;
    margin-right: 1rem;
    font-size: 1.75rem!important;
    line-height: inherit;
    white-space: nowrap;
    font-weight: 700;
    color: #1dc456!important;
}

.nav-header a {
    display: block;
    font-size: 16px;
    color: #21313a!important;
    font-weight: 600;
    padding: 20px 5px;
    margin-right: 50px;
}

.nav-header a:hover,
.nav-header a.active {
    color: #1dc456!important;
}

.hero-section {
    padding-top: 4rem;
    padding-bottom: 6rem;
    position: relative;
    background-image: linear-gradient(to right, #21313a , #1dc456);
}

.hero-section:after {
    background: inherit;
    bottom: 0;
    content: '';
    display: block;
    height: 50%;
    left: 0;
    position: absolute;
    right: 0;
    transform: skewY(-2.5deg);
    transform-origin: 100%;
    z-index: -1;
}

.client-section{
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.client-logo{
  max-width: 100px!important;
}

.leaflet-container {
  height: 900px;
  width: 95%;
  margin: 30px auto;
}
